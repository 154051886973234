import React from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  Image,
  Button,
  Flex,
  Container,
  Link,
  Spacer,
  IconButton,
  AspectRatio,
  useColorMode,
  useTheme,
} from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import Carousel from './components/Carousel';
import { AudioPlayerProvider } from './context/AudioPlayerContext';
import AudioPlayer from './components/AudioPlayer';
import AudioPlayerControls from './components/AudioPlayerControls';
import ContractSection from './components/sections/ContractSection';
import SectionTitle from './components/shared/SectionTitle';


const pulseAnimation = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;

const shineAnimation = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;

const buttonHoverAnimation = keyframes`
  0% { transform: translate(0, 0); }
  100% { transform: translate(2px, 2px); }
`;

const ColorModeToggle = () => {
  const { colorMode, setColorMode } = useColorMode();

  const colorModes = ['light', 'orange', 'dark'];

  const colorSchemes = {
    light: { bg: 'white', hover: 'gray.200' },
    orange: { bg: 'orange.100', hover: 'orange.200' },
    dark: { bg: 'gray.700', hover: 'gray.600' },
  };

  const toggleColorMode = () => {
    const currentIndex = colorModes.indexOf(colorMode);
    const nextIndex = (currentIndex + 1) % colorModes.length;
    setColorMode(colorModes[nextIndex]);
  };

  return (
    <IconButton
      aria-label="Toggle color mode"
      title={`Switch to ${colorModes[(colorModes.indexOf(colorMode) + 1) % colorModes.length]} mode`}
      icon={
        <Image 
          src="/images/ball.svg" 
          alt="Toggle theme" 
          w="30px" 
          h="30px" 
          filter="invert(0)"
        />
      }
      onClick={toggleColorMode}
      bg={colorSchemes[colorMode].bg}
      _hover={{ bg: colorSchemes[colorMode].hover }}
      transition="all 0.2s"
    />
  );
};

function App() {
  const { colorMode } = useColorMode();
  const theme = useTheme();

  const colorSchemes = {
    light: {
      primary: 'orange.500',
      secondary: 'white',
      background: 'yellow.100',
      text: 'black',
      accent: 'green.500',
    },
    orange: {
      primary: 'blue.500',
      secondary: 'yellow.300',
      background: 'orange.100',
      text: 'blue.900',
      accent: 'purple.500',
    },
    dark: {
      primary: 'purple.700',
      secondary: 'blue.300',
      background: 'gray.900',
      text: 'white',
      accent: 'red.500',
    },
  };

  const currentColors = colorSchemes[colorMode];

  const createGallerySlide = (imageSrc, alt, title, description) => ({
    image: imageSrc,
    alt: alt,
    caption: (
      <VStack spacing={4} height="100%" justifyContent="center" p={4}>
        <Heading as="h3" size="lg" color={currentColors.primary}>{title}</Heading>
        <Text fontSize="xl" color={currentColors.text}>{description}</Text>
      </VStack>
    )
  });

  const gallerySlides = [
    createGallerySlide(
      "/images/Goku-1.webp",
      "Goku Ready to Fight",
      "Ready for Battle",
      "Goku strikes his iconic pose, radiating determination and power. A classic moment that captures the essence of our Saiyan hero!"
    ),
    createGallerySlide(
      "/images/Goku-2.webp",
      "Goku Powering Up",
      "Unleashing Power",
      "Witness Goku as he channels his inner strength, preparing for an epic showdown!"
    ),
    createGallerySlide(
      "/images/Goku-3.webp",
      "Goku Super Saiyan",
      "Super Saiyan Transformation",
      "Behold the legendary Super Saiyan form, a testament to Goku's unbreakable will and limitless potential!"
    ),
  ];

  const featuredSlides = [
    createGallerySlide(
      "/images/Goku-6.webp",
      "Action Scene",
      "Reborning Hero",
      "Fun fact: Goku has died and been resurrected multiple times throughout the series. Talk about never giving up! Which of Goku's comebacks is your favorite?"
    ),
    createGallerySlide(
      "/images/Goku-7.webp",
      "Goku vs Frieza",
      "Epic Showdown",
      "The battle against Frieza on Namek is one of the most iconic fights in anime history. Goku's determination shines through even in the face of overwhelming odds!"
    ),
    createGallerySlide(
      "/images/Goku-8.webp",
      "Spirit Bomb",
      "Ultimate Technique",
      "The Spirit Bomb, Goku's ultimate technique, embodies the power of unity and hope. It's a reminder that sometimes, we're strongest when we work together!"
    ),
  ];

  return (
    <AudioPlayerProvider>
      <Box  minW="100vw" minH="100vh" bg={currentColors.background}>
        <Box as="header" bg={currentColors.primary} py={4} position="sticky" top={0} zIndex={10}>
          <Flex alignItems="center" maxW="1200px" mx="auto" px={4}>
            <Heading color="white" fontSize="xl" textShadow="2px 2px 4px rgba(0,0,0,0.5)" mr={4}>
              Dragon Ball Z - Goku Token on PulseChain.Fun
            </Heading>
            <Image src="/images/pcf.gif" alt="PulseChainFun Logo" h="50px" />
            <Spacer />
            <Flex alignItems="center">
              <AudioPlayerControls />
              <ColorModeToggle />
            </Flex>
          </Flex>
        </Box>

        <AudioPlayer isCompact={true} />

        <Container maxW="container.xl" bg={`${currentColors.background}CC`} borderRadius="xl" my={8} p={8} boxShadow="lg">
          <VStack spacing={16} align="stretch">
            <Box as="section" id="hero" position="relative" overflow="hidden" bg={currentColors.primary} borderRadius="xl" py={16}>
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bgGradient={`linear(0deg, ${currentColors.accent}33, ${currentColors.primary}33)`}
                borderRadius="40%"
                animation={`${pulseAnimation} 15s infinite linear`}
                opacity="0.6"
              />
              <Flex direction={{ base: "column", md: "row" }} justifyContent="space-between" alignItems="center" maxW="1200px" mx="auto" px={8} position="relative" zIndex={2}>
                <Box maxW={{ base: "100%", md: "45%" }} mb={{ base: 8, md: 0 }}>
                  <Heading as="h2" size="2xl" color={currentColors.secondary} mb={4} textShadow="0 0 10px rgba(0,0,0,0.5)">
                    Goku PulseChain Token: Unleash the Saiyan Spirit
                  </Heading>
                  <Text fontSize="lg" mb={6} color="white">
                    Welcome to the epicenter of Dragon Ball Z's power on PulseChain! Our Goku Token celebrates the saga that has inspired millions, bringing the energy of intense battles and unbreakable bonds to the blockchain realm.
                  </Text>
                  <Button
                    as="a"
                    href="https://v3.pulsechain.fun"
                    target="_blank"
                    rel="noopener noreferrer"
                    bg={currentColors.accent}
                    color="white"
                    fontWeight="bold"
                    px={8}
                    py={4}
                    borderRadius="full"
                    boxShadow="4px 4px 8px rgba(0, 0, 0, 0.3)"
                    _hover={{
                      bg: currentColors.secondary,
                      color: currentColors.text,
                      boxShadow: "6px 6px 12px rgba(0, 0, 0, 0.4)",
                      animation: `${buttonHoverAnimation} 0.3s ease-in-out forwards`,
                    }}
                    transition="all 0.3s ease-in-out"
                  >
                    Power Up with Goku Token
                  </Button>
                </Box>
                <Box maxW={{ base: "100%", md: "45%" }}>
                  <AspectRatio ratio={16 / 9} maxW="600px" borderRadius="xl" overflow="hidden" boxShadow="0 0 20px rgba(0, 0, 0, 0.5)" mb={4}>
                    <video autoPlay loop muted playsInline aria-label="Goku transformation video">
                      <source src="/videos/goku-transformation.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </AspectRatio>
                  <Image src="/images/pulsechain-banner.webp" alt="PulseChain Banner" w="100%" maxW="500px" />
                </Box>
              </Flex>
            </Box>
            
            <Box as="section" id="gallery" mb={10}>
            <SectionTitle>Gallery of Legendary Transformations</SectionTitle>
            <Box height={["40vh", "30vh", "40vh"]} width="100%">
            <Carousel slides={gallerySlides} autoplayInterval={5000} direction="normal" />
            </Box>
            </Box>

            <Box as="section" id="featured" mb={12}>
            <SectionTitle>Legendary Moments</SectionTitle>
            <Box height={["40vh", "30vh", "40vh"]} width="100%">
            <Carousel slides={featuredSlides} autoplayInterval={6000} direction="reverse" />
            </Box>
            </Box>

            <Box as="section" id="second-video" textAlign="center">
              <SectionTitle>More Action</SectionTitle>
              <Box maxW="800px" mx="auto" borderRadius="xl" overflow="hidden" boxShadow="0 0 20px rgba(0, 0, 0, 0.5)">
                <video autoPlay loop muted playsInline aria-label="Epic battles video">
                  <source src="/videos/epic-battles.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
              <Text mt={4} maxW="600px" mx="auto" color={currentColors.text}>
                The "Genkidama" moment... Witness the key moments from the series, showcasing character growth, pivotal battles, and unbreakable bonds.
              </Text>
            </Box>

            <Box as="section" id="third-video" textAlign="center">
              <SectionTitle>Even More Action</SectionTitle>
              <Box maxW="800px" mx="auto" borderRadius="xl" overflow="hidden" boxShadow="0 0 20px rgba(0, 0, 0, 0.5)">
                <video autoPlay loop muted playsInline aria-label="Saiyan journey video">
                  <source src="/videos/saiyan-journey.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
              <Text mt={4} maxW="600px" mx="auto" color={currentColors.text}>
                Experience more epic moments from the Dragon Ball Z saga!
              </Text>
            </Box>
           
            // In your App render:
            <ContractSection />

            <Box as="section" id="community" textAlign="center">
              <SectionTitle>Join the DBZ-Goku Community</SectionTitle>
              <Text maxW="600px" mx="auto" mb={6} color={currentColors.text}>
                Connect with fellow Dragon Ball Z Goku fans through the Pulsechain Token deployed on PulseChain.Fun and share your passion for the series!
              </Text>
              <Flex justifyContent="center" gap={4} flexWrap="wrap">
                <Button
                  as={Link}
                  href="https://x.com/DBZ_Goku_Fun"
                  target="_blank"
                  rel="noopener noreferrer"
                  bg={currentColors.accent}
                  color="white"
                  _hover={{ transform: 'translateY(-3px)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
                >
                  Twitter: @DBZ_GoKu_Fun
                </Button>
                <Button
                  as={Link}
                  href="https://t.me/DBZ_GoKu_Fun"
                  target="_blank"
                  rel="noopener noreferrer"
                  bg={currentColors.accent}
                  color="white"
                  _hover={{ transform: 'translateY(-3px)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
                >
                  Telegram: @DBZ_GoKu_Fun
                </Button>
              </Flex>
            </Box>
          </VStack>
        </Container>

        <Box as="footer" bg={currentColors.primary} color="white" textAlign="center" py={4} mt={8}>
          <Text fontSize="sm" maxW="container.xl" mx="auto" px={4}>
            Disclaimer: All characters and their associated images are used for illustrative purposes only and are not intended to infringe upon any copyright or trademark. We do not claim ownership of any character tokens or images displayed on this website.
          </Text>
        </Box>
      </Box>
    </AudioPlayerProvider>
  );
}

export default App;